<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o arquivo de dados a ser importado
    </div>

    <v-row class="mx-auto">
      <v-col cols="12">
        <v-checkbox 
          label="Apagar base atual" 
          v-model="apagarBaseAtual" 
          class="mt-0"
          hide-details
        />
      </v-col>
      <v-col cols="12" v-if="apagarBaseAtual === true">
        <v-alert
          dense 
          outlined 
          type="warning" 
          class="mb-4 py-1 px-1"
        >
          <div>
            <strong>Atenção:</strong> essa opção apagará os dados existentes na base antes de importar os novos. Se desejar apenas adicionar dados novos ou atualizar registros existentes, desmarque esta opção.
          </div>
        </v-alert>
      </v-col>
    </v-row>

    <v-row 
      v-if="!apagarBaseAtual && 
            formattedFieldsCategories.length > 0 && 
            dataImportType.tipo === 'IndicadoresServicosV2'"
      class="mx-auto "
    >
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel >
              <v-expansion-panel-header>Serviços Selecionados</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col v-if="servicosEmSelecionamento.length < 1" cols="12" class="caption">
                    <strong style="color: red;">Selecione pelo menos 1 serviço</strong>
                  </v-col>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selecionar_todos_servicos"
                      label="Selecionar todos os serviços"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="4" sm="4" cols="1" 
                    v-for="categoria in servicosCategories" 
                    :key="categoria.id"
                  >
                    <v-checkbox 
                      v-model="servicosEmSelecionamento"
                      :label="categoria.nome"
                      :value="categoria.id"
                    />
                  </v-col>
                </v-row>
                <v-row 
                  v-if="false" 
                  class="mt-3" 
                  justify="center"
                >
                  <v-btn
                    color="primary"
                    elevation="5"
                    small
                    :disabled="loadingDados"
                    @click="aplicarServicosSelecionados()"
                  >
                    Aplicar serviços
                  </v-btn>
                </v-row>
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row 
      v-if="!apagarBaseAtual && formattedFieldsCategories.length > 0"
      class="mx-auto mb-5"
    >
      <v-col cols="12">
        <v-expansion-panels :value="0">
          <v-expansion-panel>
            <v-expansion-panel-header>Temas Atualizados</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col v-if="selectedCategories.length < 1" cols="12" class="caption">
                  <strong style="color: red;">Selecione pelo menos 1 tema</strong>
                  <validation-provider rules="required|min:1">
                    <v-text-field
                      v-show="false"
                      type="text"
                      :value="selectedCategories.join(',')"
                    />
                  </validation-provider>
                </v-col>
                <v-col class="pb-0 pt-0" cols="12">
                  <v-checkbox 
                    v-model="selectAllCategories"
                    label="Selecionar todos os temas"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col 
                  class="pb-0 pt-1" 
                  cols="auto" 
                  v-for="category in formattedFieldsCategories" 
                  :key="category.slug"
                >
                  <v-checkbox 
                    v-model="selectedCategories"
                    :label="category.category"
                    hide-details
                    :value="category.slug"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row
      id="importacao-encoding"
      class="mx-auto"
    >
      <v-col cols=12>
        <select-encoding
          id="fs-encoding-importacao-dados"
          label="Codificação do arquivo"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row> 

    <v-row id="importacao-upload-files">
      <v-col cols=12>
        <upload-files
          id="fs-upload-importacao-dados" 
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        >
          <template v-slot:upload-alert-extra-information>
            <link-download-arquivo-modelo
              id="fs-link-download-importacao-dados"
              :tipoImportacao="dataImportType"
            />
          </template>
        </upload-files>
      </v-col>
    </v-row>
    <v-row v-if="lastUploadedFile">
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>    
  </div>    
</template>

<script>

import ImportacoesDadosService from '@/services/ImportacoesDadosService';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding: () => import('@/components/general/SelectEncoding.vue'),
    UploadFiles: () => import('@/components/general/UploadFiles/Index.vue'),
    LinkDownloadArquivoModelo: () => import('@/components/administracao/importacoes-dados/LinkDownloadArquivoModelo.vue')
  },
  props: {
    dataImportType: {
      type: Object,
      default: null 
    },
    formattedFieldsCategories: {
      type: Array,
      required: true 
    },
    servicosCategories: {
      type: Object,
      required: true 
    },
  },
  data() {
    return {
      encoding: 'UTF-8',
      allowedFiles: [],
      uploadedFiles: [],
      apagarBaseAtual: false,  
      selectAllCategories: false,
      selectedCategories: [],
      servicosCategories: [],
      servicos_selecionados: [],
      servicosEmSelecionamento: [],
      selecionar_todos_servicos: false,
    }  
  },  
  mounted() {
    this.getAllowedFiles();
    this.encoding = this.dataImportType.encoding;  
    
    this.selectAllCategories = true;
    this.selecionar_todos_servicos = true;
  },
  watch: {
    encoding() {
      this.$emit('encoding:selected', this.encoding);  
    },
    apagarBaseAtual() {
      this.$emit('apagarBaseAtual:changed', this.apagarBaseAtual);  
      this.selectedCategories = this.formattedFieldsCategories.map(category => category.slug);
    },
    uploadedFiles() {
      this.$emit('file:uploaded', this.lastUploadedFile);
    },
    dataImportType() {
      this.encoding = this.dataImportType.encoding;
      this.selectedCategories = this.formattedFieldsCategories.map(category => category.slug);
    },
    selectAllCategories() {
      this.selectedCategories = this.selectAllCategories 
        ? this.formattedFieldsCategories.map(category => category.slug)
        : [];
    },
    selectedCategories() {
      this.$emit('selectedCategories:changed', this.selectedCategories);
    },
    servicosEmSelecionamento() {
      this.$emit('selectedServicesCategories:changed', this.servicosEmSelecionamento);
    },
    selecionar_todos_servicos() {
      this.servicosEmSelecionamento = this.selecionar_todos_servicos 
        ? this.servicosCategories.map(categoria => categoria.id) 
        : [];
    },
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    getAllowedFiles() {
      ImportacoesDadosService
        .getAllowedFiles()
        .then(response => this.allowedFiles = response.data)
        .catch(error => console.log('Error: ' + error));    
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach(file => this.uploadedFiles.push(file));
    },
    encodingSelected(event) {
      this.encoding = event;
    }
  }
}

</script>
